<script setup>
import {usePrefetchData,LOADED,} from '@/assets/js/src/util/composables/usePrefetchData'
import {usePartnerStore,} from '@/assets/js/src/modules/partner/_pinia/partner'
import {storeToRefs,getActivePinia,} from 'pinia'
import LoadingDummyBox from '@/assets/js/src/components/loading/LoadingDummyBox'

defineProps({
    shadow: {
        type: Boolean,
        default: true,
    },
})
    
let partnerStore = usePartnerStore(getActivePinia())

const {publishers,} = storeToRefs(partnerStore)
const {asyncStatus,} = usePrefetchData({
    actions: [
        {
            item: publishers,
            action: partnerStore.getPublishers,
            payload: {},
        },
    ],
})
</script>

<template>
    <v-row
        class="partner-list flex-wrap justify-start"
    >
        <template
            v-if="asyncStatus === LOADED"
        >
            <v-col
                v-for="publisher in publishers"
                :key="publisher.id"
                lg="2"
                md="3"
                sm="4"
                cols="6"
            >
                <div
                    class="partner-list__card"
                    :class="{'elevation-1':shadow}"
                >
                    <v-tooltip
                        :ref="`pub_${publisher.id}`"
                        location="bottom"
                        offset="8"
                        :close-delay="200"
                        attach="#teleported"
                        content-class="partner-list__pub_tooltip"
                        tag="div"
                    >
                        <template #activator="{ props }">
                            <a
                                :href="publisher.url"
                                target="_blank"
                                rel="noopener"
                                v-bind="props"
                            >
                                <bs-v-img
                                    :src="publisher.logo"
                                    :alt="publisher.name"
                                    :max-height="90"
                                    :aspect-ratio="16/9"
                                    contain
                                >
                                    <template #placeholder>
                                        <v-row
                                            class="fill-height ma-0"
                                            align="center"
                                            justify="center"
                                        >
                                            <v-progress-circular
                                                indeterminate
                                                :class="['secondary--text']"
                                            ></v-progress-circular>
                                        </v-row>
                                    </template>
                                </bs-v-img>
                            </a>
                        </template>
                        <div
                            @mouseover="$refs[`pub_${publisher.id}`][0].value = true"
                            @mouseleave="$refs[`pub_${publisher.id}`][0].value = false"
                        >
                            <h4>{{ publisher.name }}</h4>
                            <section
                                v-html="publisher.description"
                            ></section>
                        </div>
                    </v-tooltip>
                </div>
            </v-col>
        </template>
        <template v-else>
            <v-col
                v-for="n in 18"
                :key="'partner_lb_'+n"
                lg="2"
                md="3"
                sm="4"
                cols="6"
            >
                <loading-dummy-box></loading-dummy-box>
            </v-col>
        </template>
    </v-row>
</template>

<style lang="scss">
    .bs-app {
        .partner-list {
            margin-top: 24px !important;

            &__card {
                margin: map-deep-get($bs-xl, overlay, grid, margin);
                padding: 28px 20px;
                background-color: map-deep-get($bs-color, white);
                cursor: pointer;
                transition: all .3s;
            }
        }

        ~ #teleported {
            .partner-list__pub_tooltip {
                z-index: 10000;
                max-width: 300px!important;
                color: white;

                section p:last-of-type {
                    margin: 0;
                }
            }
        }
    }
</style>