import LoadingDummy from '@/assets/js/src/components/LoadingDummy.vue'
import colors from '@/assets/js/src/style/json/colors.json'
import {h,} from 'vue'

export default {
    name: 'LoadingDummyBox',
    render () {
        return h(LoadingDummy,
            {
                itemsPerRow:[ [ {
                    height:'154px',
                    width:'calc(100% - 4px)',
                    margin:'4px',
                    background: this.$vuetify.theme.current.dark ? colors.dark.grey : colors.grey,
                    visible: true,
                }, ], ],
            }
        )
    },
}
