import fetch from '@/assets/js/src/util/fetch'
import {checkResponse, handleException,} from '@/assets/js/src/util/apiTools'
import {getDefaultHeaders,} from '@/assets/js/src/util/fetch/defaultHeaders'
import {defineStore, getActivePinia,} from 'pinia'

const API_GET_PUBLISHERS = '/api/publisher'

export const usePartnerStore = defineStore('partner', {
    state: () => ({
        publishers: {},
    }),
    actions: {
        async getPublishers () {
            let activePinia = getActivePinia()
            let rootState = activePinia.state.value

            try {
                let useCache = true
                let apiResponse = await fetch({
                    url: API_GET_PUBLISHERS,
                    options: {
                        headers: getDefaultHeaders({
                            rootState,
                            useCache,
                        }),
                    },
                    useCache,
                    tags: [
                        'publisher',
                    ],
                })

                // Api-Response prüfen
                if (checkResponse(apiResponse)) {
                    throw new Error('Fehler in Api-Response')
                }

                this.publishers = apiResponse.data

            } catch (e) {
                // Evtl. Fehler ausgeben
                handleException(e, true)
            }
        },
    },
})
